@mixin center-vertically($position: absolute) {
  position: $position;
  top: 50%;
  transform: translateY(-50%); }

@mixin center-horizontally($position: absolute) {
  position: $position;
  left: 50%;
  transform: translateX(-50%); }

@mixin center($position: absolute) {
  position: $position;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

