.neon {
  font-family: "marquee";
  text-align: center;
  font-size: 70px;
  color: #fef;
  text-shadow: 0 -20px 60px, 0 0 2px, 0 0 1em rgba($pink, 60), 0 0 20px rgba($pink, 60), 0 0 32px rgba($pink, 60), 0 10px 3px rgba($pink, 0.3);
  letter-spacing: 10px; }

.neon span {
  animation: blink linear infinite 6s; }

.neon span:nth-of-type(2) {
  animation: blink linear infinite 6s; }

@keyframes blink {
  78% {
    color: inherit;
    text-shadow: inherit; }
  79% {
    color: rgba($pink, 30); }

  80% {

    text-shadow: none; }

  81% {
    color: inherit;
    text-shadow: inherit; }

  82% {
    color: rgba($pink, 30);
    text-shadow: none; }

  83% {
    color: inherit;
    text-shadow: inherit; }

  92% {
    color: rgba($pink, 30);
    text-shadow: none; }

  93% {
    color: inherit;
    text-shadow: inherit; } }

@media only screen and (min-width: 1000px) {
  .neon {
    font-size: 100px;
    letter-spacing: 25px; } }
