.menu {}

.menu__folders {
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: scroll; }

.menu__item {
  display: flex;
  align-items: center;
  @include margin-bottom(.5);
  @include font-size(regular);
  overflow: auto;
  max-height: 500px;

  svg {
    width: 24px;
    @include margin-right(.5); }

  a, button {
    text-decoration: none;
    &:hover {
      text-decoration: underline; } } }

.menu__item--justify {
  @extend .menu__item;
  justify-content: space-between; }

.menu__item__label {
  @include margin-right(2); }

.menu__item__info {
  max-width: 50%;
  overflow: scroll; }

.menu__item__icon {
  @include margin-left(.5);
  display: flex;
  align-items: center; }

.csv-input, .csv-input[type="file"] {
  @include font-size(smallprint);
  font-weight: normal;
  @include body-font-family; }
