$yellow: #fcff5c;
$pink: #ffcaff;
$green: #24fcc9;
$dark-purple: #1f1630;
$blue: #22c1c3;
$peach: #FF945A;
$ivory: #FFFDED;
$gradient: linear-gradient(45deg, #fffca166 20%, #ffcaff66 100%);
$green-gradient: linear-gradient(45deg, #fffca166 10%, #24fcc966 100%);
$light-gradient: radial-gradient(89% 89% at 50% 0%, rgba(#ffcaff, 0.4) 5%, rgba(255, 252, 161, 0.6) 60%, #fff 100%);
$light-blue-gradient: radial-gradient(89% 89% at 50% 0%, rgba($green, 0.4) 5%, rgba(255, 252, 161, 0.6) 60%, #fff 100%);
$white-blue-gradient: radial-gradient(89% 89% at 50% 0%, rgba($green, 0.4) 5%, white 60%, #fff 100%);

$shadow: 0 0.9px 2.2px rgba(0,0,0,0.02),0 2.2px 5.3px rgba(0,0,0,0.028),0 4.1px 10px rgba(0,0,0,0.035),0 7.4px 17.9px rgba(0,0,0,0.042),0 13.8px 33.4px rgba(0,0,0,0.05),0 33px 80px rgba(0,0,0,0.07);

$base-body-color: #0C0326;
$light-body-color: #a6a6a6;
$placeholder-body-color: #c6c6c6;

$light-bg-color: #f9f9f9;
$disabled-color: #e0e0e0;

$border-color: #f0f0f0;
$alert-color: #ff5e49;
$warning-color: #eca227;
$notice-color: #46d700;

$add-color: #4CB06D;
$remove-color: #EB576A;

$primary-color: $green;
$accent-color: $pink;
$slightly-transparent-accent-color: rgba(#70c8dc, 0.7);
$light-color: #e1edf2;
$dark-color: #1a2a31;

$light-primary-color: #24fcc966;
$light-secondary-color: rgba($accent-color, 0.4);
$background-color: white;
$button-color: $pink;
