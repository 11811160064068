.outline {
  font-family: $heading-font-family;
  line-height: 1;
  position: relative;
  text-decoration: none;
  color: $base-body-color;
  border: 2px solid $base-body-color;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  @include vertical-padding(4); }

a.outline {
  cursor: pointer;
  white-space: nowrap;
  &.is-active {
    border: 2px solid $button-color; }
  &:hover {
    border: 2px solid $button-color;
    transition: $transition;
    text-decoration: none !important; } }

.outline__title {
  font-weight: bold;
  @include margin-bottom(1); }

.outline__button {
  background: $green-gradient;
  @include margin-top(1);
  padding: 10px 16px;
  line-height: 1;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  border-radius: 3px;
  text-align: center;
  font-weight: bold;
  @include font-size(smallprint); }
