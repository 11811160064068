.form {
  border-radius: 3px;
  font-family: $heading-font-family;
  @include font-size(regular);
  text-align: left;

  label {
    display: block;
    text-align: left;
    font-weight: bold;
    flex: 1;
    display: block;
    padding-bottom: 0.4rem;
    @include margin-right(.5); }

  input, select {
    position: relative;
    border: none;
    border-radius: 0;
    background-color: #fff;
    padding: 0.4rem 0;
    width: 100%; }

  input {
    border-bottom: 1px dashed $base-body-color;
    &:disabled {
      color: $disabled-color; } }

  textarea {
    border: 1px solid $light-body-color;
    @include vertical-margin(2);
    border-radius: 3px; } }

.form__errors {
  @include font-size(smallprint);
  color: red;
  font-weight: normal;
  text-align: end; }

.form__row {
  display: flex;
  align-items: flex-end;
  @include margin-bottom(2); }

@media only screen and (min-width: 500px) {
  .form {
    label {
      span {
        display: inline-block; } }

    input, select {
      width: 70%; } } }
