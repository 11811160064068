$georgia: Georgia, Cambria, "Times New Roman", Times, serif;
$helvetica: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$lucida-grande: "Lucida Grande", Tahoma, Verdana, Arial, sans-serif;
$monospace: "Menlo", "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
$verdana: Verdana, Geneva, sans-serif;

$heading-font-weight: 700;
$heading-font-family: "Montserrat", "colfax-web", "Roboto", $helvetica;

$body-font-family: "Montserrat", "colfax-web", "Roboto", $helvetica;
$body-font-weight: normal;

$monospaced-font-family: "Roboto Mono", $monospace;
$monospaced-font-weight: normal;
