.table {
  color: $base-body-color; }

.table__container {
  position: relative;
  border-radius: 5px;
  height: 100%;
  box-shadow: $shadow;
  @include font-size(regular); }

.table__visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1; }

.table__head {
  background-color: $light-bg-color; }

.table__cell span {
  font-family: $body-font-family;
  @include font-size(regular);
  border-color: $border-color; }

td.table__cell {
  border-color: $border-color;
  color: $base-body-color;
  font-family: $body-font-family;
  @include font-size(regular); }

.MuiTablePagination-toolbar p,
.MuiTablePagination-toolbar div {
  font-family: $body-font-family;
  @include font-size(regular);
  border-color: $border-color; }

.table__cell__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end; }
