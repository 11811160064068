.section {
  position: relative;
  @include wrap; }

.section__title {
  text-align: center;
  @include font-size(big);
  margin: 0 auto;
  font-weight: bold;
  @include margin-bottom(4);
  max-width: 85%; }

.section__subtitle {
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  @include margin-bottom(4); }

.section.center {
  text-align: center;
  div {
    margin: 0 auto; } }

.blurred {
  filter: blur(3px); }

@media only screen and (min-width: 1000px) {
  .section {
    @include font-size(gamma); }

  .section__title {
    @include font-size(big);
    @include margin-bottom(6); }

  .section__link {
    @include margin-top(2); }

  .section__text {
    max-width: 85%;
    margin: 0 auto;
    @include font-size(gamma);
    @include margin-bottom(3); } }
