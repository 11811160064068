@mixin space-rule($rule, $value) {
  @include rem-rule($rule, $value * $space-unit); }

@mixin margin-left($value) {
  @include space-rule(margin-left, $value); }

@mixin margin-right($value) {
  @include space-rule(margin-right, $value); }

@mixin margin-bottom($value) {
  @include space-rule(margin-bottom, $value); }

@mixin margin-top($value) {
  @include space-rule(margin-top, $value); }

@mixin horizontal-margin($value) {
  @include margin-left($value);
  @include margin-right($value); }

@mixin vertical-margin($value) {
  @include margin-top($value);
  @include margin-bottom($value); }

@mixin margin($value) {
  @include space-rule(margin, $value); }

@mixin padding-left($value) {
  @include space-rule(padding-left, $value); }

@mixin padding-right($value) {
  @include space-rule(padding-right, $value); }

@mixin padding-bottom($value) {
  @include space-rule(padding-bottom, $value); }

@mixin padding-top($value) {
  @include space-rule(padding-top, $value); }

@mixin horizontal-padding($value) {
  @include padding-left($value);
  @include padding-right($value); }

@mixin vertical-padding($value) {
  @include padding-top($value);
  @include padding-bottom($value); }

@mixin padding($value) {
  @include space-rule(padding, $value); }

@mixin line-height($value) {
  @include space-rule(line-height, $value); }

@mixin height($value) {
  @include space-rule(height, $value); }

@mixin top($value) {
  @include space-rule(top, $value); }

@mixin left($value) {
  @include space-rule(left, $value); }

@mixin right($value) {
  @include space-rule(right, $value); }

@mixin bottom($value) {
  @include space-rule(bottom, $value); }

