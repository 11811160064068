/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div> */

.ReactTags__container {
  display: flex;
  max-width: 300px;
  min-height: 2em;
  flex-wrap: wrap; }

.react-tags {
  position: relative;
  border-radius: 4px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1;

  /* clicking anywhere will focus the input */
  cursor: text;

  &.is-focused {
    border-color: #B1B1B1; } }

.react-tags__selected {
  display: inline; }

.react-tags__tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 3px 5px;
  border: 1px solid #D1D1D1;
  border-radius: 4px;
  /* match the font styles */
  font-size: .9em;
  line-height: inherit; }

.react-tags__selected-tag {
  @extend .react-tags__tag;
  &:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px; }

  &:hover, &:focus {
    border-color: #B1B1B1; } }

.react-tags__close {
  font-weight: bold;
  margin-left: 6px;
  cursor: pointer; }

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;

  /* prevent autoresize overflowing the container */
  max-width: 100%; }

.ReactTags__icon {
  @include margin-right(.5);
  width: 18px;
  border-radius: 20px;
  color: $light-body-color; }

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative; } }

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  background: transparent;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;

  &::-ms-clear {
    display: none; } }

.react-tags__suggestions {
  position: absolute;
  z-index: 3;
  top: 100%;
  left: 0;
  width: 100%;

  ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }

  li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;

    mark {
      text-decoration: underline;
      font-weight: 600; }

    &:hover {
      cursor: pointer; }

    &.is-disabled {
      opacity: 0.5;
      cursor: auto; } } }

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 240px; } }
