.button {
  display: inline-flex;
  padding: 0.8em 1.6em;
  line-height: 1;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  background: $gradient;
  border: 1px solid $base-body-color;

  svg:last-child {
    height: 1em;
    width: 1em;
    margin-left: 1em; } }

.button--full {
  display: inline-flex;
  padding: 0.8em 1.6em;
  line-height: 1;
  position: relative;
  white-space: nowrap;
  text-decoration: none;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  font-size: 1em;
  background: $yellow;
  border: none;
  @include font-size(smallprint);
  box-shadow: $shadow;
  text-transform: uppercase;
  font-weight: 600; }

.button--big {
  @extend .button;
  padding: 18px 30px;
  font-size: 20px; }

.button--small {
  @extend .button;
  padding: 12px 22px;
  @include font-size(smallprint); }

.button--center {
  margin: 0 auto;
  display: inline-block; }

.button__link {
  &:hover {
    transition: $transition;
    text-decoration: none; }
  i {
    margin-left: 5px; } }

.button-label {
  @include body-font-family;
  @include font-size(regular);
  font-weight: bold;
  padding: 15px 25px;
  background: $green-gradient;
  background-size: 200%;
  box-shadow: $shadow;
  border-radius: 10px;
  @include horizontal-margin(2);
  text-transform: uppercase;
  background-position: right;
  transition: $transition;
  &:hover {
    background-position: left;
    transition: $transition;
    text-decoration: none; } }

@media only screen and (min-width: 1000px) {
  .button {
    border: 1px solid $base-body-color;
    background: white;
    box-shadow: none;

    &:hover {
      transition: $transition;
      background: $gradient;
      box-shadow: $shadow; } } }
