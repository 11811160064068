.half {
  text-align: left;
  @include vertical-padding(2);
  position: relative;
  display: flex;
  flex-direction: column;
  @include font-size(regular); }

.half:nth-of-type(2) {
  @extend .half;
  flex-direction: column-reverse;
  .half__left {
    margin-bottom: 0; }
  .half__right {
    @include margin-bottom(1); } }

.half__title {
  @include heading-font-family;
  @include font-size(gamma); }

.half__text {
  @include margin-bottom(1); }

.half__item {
  max-width: 700px;
  margin: 0 auto;
  text-align: center; }

.half__right, {
  @extend .half__item; }

.half__left {
  @extend .half__item;
  @include margin-bottom(2); }

.half__border {
  padding: 20px;
  border: 2px dashed $base-body-color;
  max-width: 500px; }

.half__line {
  text-align: center;
  @include vertical-margin(2);
  font-size: 7vh;
  line-height: 10px; }

.half__line--pink {
  @extend .half__line;
  color: $pink;
  text-shadow: 0 -10px 30px, 0 0 2px, 0 0 1em #fc3df660, 0 0 0.5em #fc3df660, 0 0 32px #fc3df660, 0 10px 3px #fc3df630; }

.half__line--green {
  @extend .half__line;
  color: $green;
  text-shadow: 0 -10px 30px, 0 0 2px, 0 0 1em #2b4e9960, 0 0 0.5em #2b4e9960, 0 0 32px #2b4e9960, 0 10px 3px #2b4e9930; }

.half__line--yellow {
  @extend .half__line;
  color: $yellow;
  text-shadow: 0 -10px 30px, 0 0 2px, 0 0 1em #2b4e9960, 0 0 0.5em #2b4e9960, 0 0 32px #2b4e9960, 0 10px 3px #2b4e9930; }

.half__reaction {
  text-align: center;
  @include margin-top(2); }

@media only screen and (min-width: 1000px) {
  .half {
    flex-direction: row;
    align-items: center;
    flex-basis: 50%;
    @include font-size(gamma); }

  .half:nth-of-type(2) {
    flex-direction: row; }

  .half__title {
    @include font-size(beta); }

  .half__item {
    width: 50%;
    margin: 0;
    text-align: left; }

  .half__left {
    padding-right: 60px; }

  .half__right {
    padding-left: 60px; }

  .half__border {
    padding: 60px; }

  .half__line {
    @include center;
    font-size: 60px;
    margin: 0; } }
