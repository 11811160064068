@mixin primary-color-rule($rule) {
  #{$rule}: $primary-color;
  #{$rule}: var(--primary-color); }

@mixin accent-color-rule($rule) {
  #{$rule}: $accent-color;
  #{$rule}: var(--accent-color); }

@mixin slightly-transparent-accent-color-rule($rule) {
  #{$rule}: $slightly-transparent-accent-color;
  #{$rule}: var(--slightly-transparent-accent-color); }

@mixin light-color-rule($rule) {
  #{$rule}: $light-color;
  #{$rule}: var(--light-color); }

@mixin dark-color-rule($rule) {
  #{$rule}: $dark-color;
  #{$rule}: var(--dark-color); }
