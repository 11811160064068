@mixin heading-font-family {
  font-weight: $heading-font-weight;
  letter-spacing: 0; }

@mixin body-font-family {
  font-family: $body-font-family;
  font-weight: $body-font-weight;
  letter-spacing: 0; }

@mixin monospaced-font-family {
  font-family: $monospaced-font-family;
  font-weight: $monospaced-font-weight;
  letter-spacing: -0.03em; }

