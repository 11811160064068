.header {
  @extend .section; }

.header__container {
  max-width: 840px;
  margin: 0 auto;
  padding: 50px 15px;
  padding-top: 20px; }

.header__title {
  @include heading-font-family;
  text-align: center;
  font-weight: 900;
  @include margin-bottom(5);
  @include font-size(alpha);
  font-weight: bold;
  text-align: center; }

.header__subtitle {
  text-align: center;
  @include font-size(regular);
  @include margin-bottom(1); }

.header__image {
  display: none; }

@media only screen and (min-width: 1000px) {
  .header__container {
    padding: 100px 0;
    max-width: 950px;
    padding-top: 50px; }

  .header__title {
    @include font-size(huge); }

  .header__subtitle {
    @include font-size(beta);
    text-align: left; }

  .header__image {
    display: block;
    width: 100px; } }
