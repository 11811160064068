.flag {
  text-align: left;
  @include vertical-padding(2);
  @include font-size(regular); }

.flag__title {
  @include heading-font-family;
  @include font-size(beta);
  @include margin-bottom(1); }

.flag__text {}

.flag__image {
 }  // max-width: 100%

.flag__video {
  width: 100%;
  box-shadow: 2px 14px 15px #ddd; }

.flag__item, .flag__image__container {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  @include margin-bottom(2); }

.flag__right, .flag__left {
  @extend .flag__item; }

.flag__image__container {
  @include margin-bottom(4); }


@media only screen and (min-width: 1000px) {
  .flag {
    display: flex;
    align-items: center;
    @include font-size(gamma); }

  .flag__image__container {
    flex-basis: 60%;
    flex-grow: 0;
    margin-bottom: 0; }

  .flag__item {
    flex-basis: 40%;
    flex-grow: 0;
    margin: 0;
    max-width: none;
    text-align: left; }

  .flag__left {
    padding-right: 60px; }

  .flag__right {
    padding-left: 60px; }

  .flag__title {
    @include margin-bottom(2);
    @include font-size(alpha); } }
