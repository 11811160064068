.page {
  position: relative;
  @include font-size(regular);
  min-height: 70vh;
  @include vertical-padding(3); }

.page__title {
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  @include margin-bottom(2);
  @include font-size(alpha);
  font-weight: 900; }

.page__subtitle {
  text-align: center;
  max-width: 85%;
  margin: 0 auto;
  @include margin-bottom(3);
  @include font-size(alpha);
  font-weight: 500; }

.page__text {
  @include margin-bottom(2);
  @include font-size(regular);
  font-weight: regular; }

@media only screen and (min-width: 1000px) {
  .page {
    @include font-size(gamma); }

  .page__title {
    @include font-size(big);
    @include margin-bottom(4); }

  .page__text {
    max-width: 85%;
    margin: 0 auto;
    @include font-size(gamma);
    @include margin-bottom(3); } }
