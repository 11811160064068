.ternary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: $heading-font-family;
  font-weight: 400;
  font-size: 20px; }

.ternary__item {
  flex-grow: 0;
  text-align: center;
  background: white;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include margin-bottom(2); }

.ternary__title {
  font-weight: bold;
  @include margin-bottom(2);
  @include font-size(gamma); }

.ternary__text {
  @include margin-bottom(1);
  font-size: 17px; }

.ternary__video {
  width: 85%; }

.ternary__line {
  text-align: center;
  @include margin-bottom(3);
  font-size: 7vh;
  line-height: 3px; }

.ternary__line--pink {
  @extend .ternary__line;
  color: $pink; }

.ternary__line--green {
  @extend .ternary__line;
  color: $green; }

.ternary__line--yellow {
  @extend .ternary__line;
  color: $yellow; }

.ternary__huge {
  @include font-size(alpha);
  font-weight: 700; }

@media only screen and (min-width: 900px) {
  .ternary {
    flex-direction: row; }

  .ternary__item {
    flex-basis: 30%;
    margin-bottom: $grid-gutter; }

  .ternary__video {
    width: 240px;
    height: 100px; } }
