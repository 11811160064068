.typist {
  @include font-size(regular);
  background-color: #fff1;
  color: white;
  font-weight: 500;
  padding: 37px;
  border-radius: 3px;
  max-width: 500px;
  height: 250px;
  margin: 0 auto;
  text-align: left;
  min-width: 250px; }

.typist--white {
  @extend .typist;
  background-color: #9991;
  color: $base-body-color; }

@media only screen and (min-width: 1000px) {
  .typist {
    width: 300px; } }

.typist--animated {
  font-family: $heading-font-family;
  background-color: $base-body-color;
  padding: 10px 20px;
  border-radius: 3px;
  color: white;
  margin: 0 auto;
  @include margin-bottom(2);
  animation: click linear 1s;
  animation-delay: 1s;
  text-align: center; }

@keyframes click {
  25% {
    transition: $transition;
    background-color: rgba($pink, 30); }
  54% {
    background-color: rgba($pink, 30); }
  57% {
    background-color: $base-body-color; } }
