.social {
  display: flex;
  align-items: center;
  justify-content: center;
  @include margin-top(2); }

.social__counter {
  @include margin-left(2);
  font-weight: bold;
  font-size: 35px;
  width: 58px; }

.social__container {
  width: 128px;
  height: 128px;
  position: relative; }

.social__button {
  @include center;
  width: 90%;
  transition: $transition;
  &:hover {
    width: 100%;
    transition: $transition; }
  &:active {
    width: 90%;
    transition: $transition; } }

.Medium {
  justify-content: center; }
