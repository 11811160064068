.docs {}

.docs__markdown {
  position: relative;
  @include font-size(regular);
  h2, h3, h4 {
    @include font-size(gamma);
    font-weight: 500;
    @include margin-bottom(1); }

  h1 {
    font-weight: bold;
    @include font-size(alpha);
    @include margin-bottom(2); }

  p {
    @include margin-bottom(2); }

  pre {
    @include margin-bottom(2);
    background-color: $light-bg-color;
    @include padding(2); }

  ul, ol, blockquote {
    padding-left: 40px;
    @include margin-bottom(2); }

  li {
    list-style-type: disc; } }

.docs__title {
  @include margin-bottom(3);
  text-align: center;
  @include font-size(alpha);
  font-weight: 900; }

.docs__text {
  @include font-size(regular);
  font-weight: regular; }

.docs__link {
  @include margin-top(1);
  display: inline-block;
  font-weight: bold;
  font-family: $heading-font-family;
  position: relative;
  text-decoration: none;
  z-index: 0;
  &:after {
    content: '';
    display: block;
    background-color: $green;
    height: 30%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20%;
    z-index: -1;
    transform: skewX(10deg);
    transition: $transition;
    width: 0%; }

  &:hover {
    &:after {
      width: 100%; } } }

.docs__image {
  max-width: 50%; }

.docs__portrait {
  max-width: 50%;
  border-radius: 3px 50%; }

.docs--button {
  @extend .docs;
  background-color: $button-color; }

.docs--primary {
  @extend .docs;
  background-color: $primary-color;
  color: white; }

.docs--black {
  @extend .docs;
  background-color: $base-body-color;
  color: white; }

.swagger-ui {
  .wrapper {
    padding: 0; }
  .info {
    margin: 0; }
  a.nostyle {
    @include font-size(gamma);
    font-weight: bold; } }

@media only screen and (min-width: 1000px) {
  .docs {
    @include font-size(gamma); }

  .docs__container {
    padding: 100px 0;
    max-width: 950px; }

  .docs__title {
    @include font-size(big);
    @include margin-bottom(4); }

  .docs__link {
    @include margin-top(2); }

  .docs__text {
    max-width: 85%;
    margin: 0 auto;
    @include font-size(gamma);
    @include margin-bottom(3); } }
