.check {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.check__text {
  white-space: nowrap;
  @include font-size(milli);
  &::before {
    content: "✓";
    @include horizontal-margin(0.5); } }

.check__icons {
  width: 30px;
  height: 30px;
  padding: 5px;
  @include margin-bottom(1.5); }

@media only screen and (min-width: 1000px) {
  .check {
    justify-content: center; }

  .check__icons {
    width: 35px;
    height: 35px; } }
