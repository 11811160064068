.footer {
  background-color: $base-body-color;
  font-size: 15px;
  color: white;
  @include vertical-padding(2);
  @include margin-top(8); }

.footer__container {
  @include wrap; }

.footer__text {
  font-family: $body-font-family;
  font-weight: 400; }

.footer__list {
  display: flex; }

.footer__icon {
  fill: white;
  &:hover {
    fill: $pink; } }

.footer__link {
  color: white;
  font-family: $body-font-family;
  font-weight: 400;
  text-decoration: underline;
  margin: 0 3px;
  &:hover {
    text-decoration: none; } }

@media only screen and (min-width: 1000px) {
  .footer {
    @include vertical-padding(4); } }
