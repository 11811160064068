.panes {
  display: flex; }

.pane {
  flex: 1;
  @include margin-left(2); }

.pane__left {
  width: 300px; }

.pane__title {
  text-align: center;
  @include margin-bottom(4);
  @include font-size(alpha);
  font-weight: 900; }

@media only screen and (max-width: 1000px) {
  .panes {
    display: block; }

  .pane {
    @include margin-left(0); }

  .pane__left {
    max-width: none;
    min-width: none;
    width: auto;
    // text-align: center
    @include margin-bottom(4); } }
