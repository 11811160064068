.space__bottom-1 {
  @include margin-bottom(1); }
.space__bottom-2 {
  @include margin-bottom(2); }
.space__bottom-3 {
  @include margin-bottom(3); }
.space__bottom-4 {
  @include margin-bottom(4); }
.space__bottom-5 {
  @include margin-bottom(5); }
.space__bottom-6 {
  @include margin-bottom(6); }

.space__top-1 {
  @include margin-top(1); }
.space__top-2 {
  @include margin-top(2); }
.space__top-3 {
  @include margin-top(3); }
.space__top-4 {
  @include margin-top(4); }

.space__left-1 {
  @include margin-left(1); }
.space__left-2 {
  @include margin-left(2); }
.space__left-3 {
  @include margin-left(3); }
.space__left-4 {
  @include margin-left(4); }

.space__right-1 {
  @include margin-right(1); }
.space__right-2 {
  @include margin-right(2); }
.space__right-3 {
  @include margin-right(3); }
.space__right-4 {
  @include margin-right(4); }
