.window {
  margin: 0 auto;
  position: relative;
  min-width: 50%;
  border-radius: 5px;
  @include padding(6);
  box-shadow: $shadow;
  max-width: 900px;
  @include font-size(regular);
  background-color: white; }

.window__container {
  @include center; }

.window__label {
  @include center-horizontally;
  font-size: 18px;
  background: $gradient;
  border-radius: 20px;
  font-weight: bold;
  @include vertical-padding(.2);
  @include horizontal-padding(1);
  top: -12px;
  text-align: center; }

.window__bigtext {
  @include font-size(huge);
  font-weight: bold;
  text-align: center; }

.window__title {
  @include font-size(gamma);
  @include margin-bottom(1);
  font-weight: bold; }

.window__text {
  @include font-size(regular);
  @include margin-bottom(4);
  font-weight: 500; }

.window__icon {
  @include margin-right(.5);
  width: 24px;
  border-radius: 20px; }

.window__mini {
  font-size: 10px; }

.blank {
  @include center;
  background: white;
  position: absolute;
  z-index: 2;
  width: 60%; }

@media only screen and (max-width: 960px) {
  .window {
    min-height: 200px;
    width: 100%;
    @include margin-bottom(4);
    @include padding(4);
    max-width: none; }

  .window {
    @include padding(4); } }
