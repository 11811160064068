.tooltip {
  @include font-size(regular);
  max-width: 300px;
  color: white;
  background-color: $base-body-color; }

.__react_component_tooltip {
  max-width: 300px; }

.tooltip__icon {
  display: flex;
  align-items: center; }

.tooltip__link {
  @include horizontal-margin(.5);
  align-self: flex-end;
  display: flex;
  svg {
    width: 24px;
    stroke: $light-body-color; } }
