@import url("https://use.typekit.net/iok7hkr.css");

html, body {
  height: 100%; }

html {
  @include rem-html-reset(0.9);

  // +at-breakpoint(desk)
 }  //   +rem-html-reset(1)

body {
  @include body-font-family;
  @include font-size(regular);
  -webkit-text-size-adjust: 100%;
  height: 100%;
  line-height: 1.5;
  color: $base-body-color;
  text-rendering: optimizeLegibility;
  font-weight: 400;
  font-size: 22px;
  min-height: 100vh;
  background-color: white; }

a {
  color: $base-body-color; }

ul, ol, blockquote {
  padding-left: 0;
  margin: 0; }

li {
  list-style-type: none; }

h1, h2, h3, h4, h5, h6, hgroup,
ul, ol, dd, p, figure, pre, table,
fieldset, hr, form {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit; }

textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  padding: 10px;
  border: 1px solid $border-color;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  background-image: none;
  transition: border 0.2s $material-ease;
  @include font-size(regular);
  resize: none; }

*:focus {
  outline: 0; }

input[type=number]::-ms-tooltip {
  display: none; }

input[type=number] {
  -moz-appearance: textfield; }

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0; }

button, input[type="submit"] {
  background-image: none;
  border-radius: 0; }

button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0; }

#{$all-text-inputs-hover}, textarea:hover {
  border-color: darken($border-color, 10%); }

#{$all-text-inputs-focus}, textarea:focus {
  @include accent-color-rule(border-color);
  box-shadow: 0 0 0 3px var(--semi-transparent-accent-color); }

#{$all-text-inputs}, textarea {
  &[disabled][disabled] {
    color: $light-body-color;
    border-color: $border-color; }

  &::placeholder {
    color: $placeholder-body-color; } }

input[type="checkbox"] {
  display: inline-block;
  margin-right: 0.4em; }

pre, code {
  @include monospaced-font-family; }

pre {
  @include padding(1);
  background-color: $light-bg-color;
  border-radius: 5px; }

hr {
  color: $border-color;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

th, td {
  text-align: left;
  @include body-font-family; }

img {
  max-width: 100%; }

legend {
  color: $light-body-color;
  @include heading-font-family; }

strong {
  font-weight: 500; }

input:-webkit-autofill {
  background-color: transparent !important;
  color: inherit !important; }

.google-maps-searchbox {
  border: 1px solid transparent;
  border-radius: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  font-size: 14px;
  height: 32px;
  margin-top: 10px;
  margin-right: 10px;
  outline: none;
  padding: 0 12px;
  text-overflow: ellipses;
  width: 250px;

  @media screen and (min-width: 1401px) {
    width: 400px; } }

div {
  box-sizing: border-box; }

.flex {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.flex--start {
  display: flex;
  align-items: center; }

.center > button,
.center > div,
.center > input,
.center > p {
  margin: 0 auto;
  text-align: center;
  display: block; }

.absolute_center {
  @include center; }

.fixed_center {
  @include center;
  position: fixed; }

.red {
  color: $remove-color !important; }

.smallprint {
  @include font-size(smallprint); }
