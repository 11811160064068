.cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include margin-bottom(4);
  height: 200px;
  position: relative; }

.cards--multi {
  @extend .cards;
  height: auto;
  align-items: baseline;
  .card {
    flex: 1;
    @include horizontal-margin(2);
    &first-child {
      @include margin-right(0); }
    &last-child {
      @include margin-left(0); } } }

.card__container {
  @include center; }

.card__label {
  @include center-horizontally;
  font-size: 12px;
  background: $gradient;
  border-radius: 20px;
  font-weight: bold;
  @include vertical-padding(.2);
  @include horizontal-padding(1);
  top: -12px;
  @include font-size(regular); }

.card {
  position: relative;
  width: 32%;
  border-radius: 5px;
  @include vertical-padding(4);
  @include horizontal-padding(2);
  height: 100%;
  box-shadow: $shadow;
  @include font-size(regular); }

.card__bigtext {
  @include font-size(huge);
  font-weight: bold;
  text-align: center;
  @include margin-bottom(2); }

.card__text {
  @include font-size(regular);
  @include margin-bottom(2); }

.card__code {
  @include font-size(regular);
  @include margin-bottom(2);
  display: block; }

.card__title {
  @include font-size(regular);
  font-weight: bold;
  @include margin-bottom(1); }

pre {
  @include margin-bottom(1);
  @include font-size(smallprint); }

.card__bullet__container {
 }  // +center-vertically

.card__bullet {
  display: flex;
  align-items: center;
  max-width: 100%;
  word-wrap: break-word;
  @include margin-bottom(1);
  @include font-size(regular);
  line-height: 1.2;
  overflow: auto;

  &:last-child {
    @include margin-bottom(0); }

  .card__bullet__num {
    @include margin-right(.5);
    font-weight: bold; } }

.card__icon {
  @include margin-right(.5);
  width: 24px;
  border-radius: 20px;
  color: $light-body-color; }

.card__mini {
  font-size: 10px; }

@media only screen and (max-width: 700px) {
  .cards {
    display: block;
    height: auto; }

  .cards--multi {
    .card {
      @include horizontal-margin(0); } }

  .card {
    min-height: 200px;
    width: 100%;
    @include margin-bottom(4);
    @include padding(4);
    max-width: none; }

  .pane__left {
    max-width: none;
    min-width: none;
    text-align: center;
    @include margin-bottom(4); } }
