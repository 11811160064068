.navbar {
  @include wrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include font-size(regular);
  @include vertical-padding(1);
  background: transparent; }

.navbar__logo {
  width: auto;
  height: auto;
  text-decoration: none;
  font-weight: bold;
  text-decoration: none;
  line-height: 1;
  a {
    display: block;
    text-decoration: none;
    width: 100%;
    height: 100%; } }

.navbar__logo--desk {
  @extend .navbar__logo;
  display: none;
  text-shadow: 5px 0 $base-body-color;
  background: linear-gradient(170deg, #fffca1 20%, #ffcaff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px; }

.navbar__logo--mobile {
  @extend .navbar__logo;
  width: 52px;
  height: 52px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../../public/logo-plain.png");
  line-height: 1em; }

.navbar__item {
  display: inline-block;
  @include margin-left(3); }

.navbar__link {
  position: relative;
  text-decoration: none;
  @include vertical-padding(.3);
  z-index: 0;
  &:after {
    content: '';
    display: block;
    background-color: $base-body-color;
    height: 1px;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: $transition;
    width: 0%; }
  &:hover {
    &:after {
      width: 100%; } } }

@media only screen and (min-width: 800px) {
  .navbar__logo--desk {
    display: block; }

  .navbar__logo--mobile {
    display: none; } }
